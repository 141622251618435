import { Navigate, RouteObject } from 'react-router-dom';

import DashboardLayout from 'components/Layouts/DashboardLayout/DashboardLayout';

import { IMenuRoute, MenuItem } from './routes.types';
import { MENU_ROUTES } from './routes';

// import type { IUser } from 'redux/reducers/serverConfigs/serverConfigs.types';

export const filterRoutesByAcl = (
  name: any,
  routeList: IMenuRoute[],
): IMenuRoute[] => {
  const routes: IMenuRoute[] = [];
  routeList.forEach(route => {
    const isHasAcces = !route.aclPath || name;
    if (isHasAcces) {
      routes.push({
        ...route,
        children: route.children
          ? filterRoutesByAcl(name, route.children)
          : undefined,
      });
    }
  });
  return routes;
};

export const createValidRoutesTree = (
  routeList: IMenuRoute[],
): IMenuRoute[] => {
  const routes: IMenuRoute[] = [];
  routeList.forEach(route => {
    if (route.children) {
      routes.push(...createValidRoutesTree(route.children));
    } else {
      routes.push({
        path: route.path,
        element: route.element,
      });
    }
  });
  return routes;
};

export const createValidMenuRoutes = (routes: IMenuRoute[]): MenuItem[] => {
  const menuItems: MenuItem[] = [];
  if (routes) {
    routes.forEach(route => {
      const currentMenu: MenuItem = {
        label: route.label || '',
        icon: route.icon || '',
        key: route.path || route.label || '',
      };

      if (route.children) {
        const childrenRoutes: MenuItem[] = createValidMenuRoutes(
          route.children,
        );
        if (childrenRoutes.length) {
          menuItems.push({ ...currentMenu, children: childrenRoutes });
        }
      } else {
        menuItems.push(currentMenu);
      }
    });
  }
  return menuItems;
};

export const createLoginRoutes = (name: any): RouteObject[] => {
  const menuValidRoutes: RouteObject[] = createValidRoutesTree(
    filterRoutesByAcl(name, MENU_ROUTES),
  );

  return [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        ...menuValidRoutes,
        {
          path: '*',
          element: <Navigate to='/' />,
        },
      ],
    },
  ];
};
