import { FC } from 'react';
import { Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';

import { useIsMobile } from 'helpers/hooks.helpers';

type PropTypes = {
  totalBet: string;
  totalWin: string;
  ggr: string;
  rtp: string;
  totalPlayers: number;
  totalSessions: number;
  serviceFee: string;
};

export const DashboardStatistics: FC<PropTypes> = ({
  totalBet,
  totalWin,
  ggr,
  rtp,
  totalPlayers,
  totalSessions,
  serviceFee,
}) => {
  const isMobile = useIsMobile();
  const isMobileSpan = isMobile ? 8 : 4;

  return (
    <Row wrap gutter={16}>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='Total Bet' description={totalBet || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='Total Win' description={totalWin || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='GGR ' description={ggr || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='RTP' description={rtp || '0%'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='Players' description={totalPlayers || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='Sessions' description={totalSessions || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }} size='small'>
          <Meta title='Service Fee' description={serviceFee || '0'} />
        </Card>
      </Col>
    </Row>
  );
};
