import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { IErrorMessage, RootState } from 'redux/store.types';
import { baseURL } from 'api/baseUrl';

import { IDashboardFiltersForm } from '../Dashboard.types';

export const useDashboardMutations = () => {
  const id = useSelector((state: RootState) => state.headerConfigs.selectedId);
  const selectedPartnerId = useSelector(
    (state: RootState) => state.headerConfigs.partnerId,
  );
  const generalMutationData = useMutation({
    mutationFn: (data: IDashboardFiltersForm) => {
      const formattedData = {
        ...data,
        timeTo: dayjs.utc(new Date(data.timeTo).toUTCString()).format(),
        timeFrom: dayjs.utc(new Date(data.timeFrom).toUTCString()).format(),
        partnerId: selectedPartnerId,
      };
      return axios.post(
        `${baseURL}/api/v1/${id}/general-report`,
        formattedData,
      );
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.error || t('Something went wrong'));
    },
  });

  const gamesMutationData = useMutation({
    mutationFn: (data: IDashboardFiltersForm) => {
      const formattedData = {
        ...data,
        timeTo: dayjs.utc(new Date(data.timeTo).toUTCString()).format(),
        timeFrom: dayjs.utc(new Date(data.timeFrom).toUTCString()).format(),
        partnerId: selectedPartnerId,
      };
      return axios.post(`${baseURL}/api/v1/${id}/games-report`, formattedData);
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.error || t('Something went wrong'));
    },
  });

  return { generalMutationData, gamesMutationData };
};
