import { FC } from 'react';
import { Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';

import { useIsMobile } from 'helpers/hooks.helpers';

import { IRoundStatistics } from '../RoundReport.types';

export const RoundStatistics: FC<IRoundStatistics> = ({
  totalBet,
  totalWin,
  ggr,
  rtp,
  totalPlayers,
  totalSessions,
}) => {
  const isMobile = useIsMobile();
  const isMobileSpan = isMobile ? 8 : 4;

  return (
    <Row wrap gutter={16}>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Total Bet' description={totalBet || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Total Win' description={totalWin || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='GGR ' description={ggr || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='RTP' description={rtp || '0%'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Players' description={totalPlayers || '0'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Sessions' description={totalSessions || '0'} />
        </Card>
      </Col>
    </Row>
  );
};
