import { Col, Form, Row, Select } from 'antd';
import { FC, useState, useEffect } from 'react';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
type PropTypes = {
  isMobile: boolean;
};

export const HeaderTimeZone: FC<PropTypes> = ({ isMobile }) => {
  const { Option } = Select;
  const labelStyle = 'original';
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [localTime, setLocalTime] = useState<string>(
    dayjs().tz(selectedTimezone).format('HH:mm:ss'),
  );

  const timezones = {
    ...allTimezones,
    'Europe/Berlin': 'Frankfurt',
  };
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    parseTimezone(selectedTimezone);

    const localTimeInterval = setInterval(() => {
      setLocalTime(dayjs().tz(selectedTimezone).format('HH:mm:ss'));
    }, 1000);

    return () => clearInterval(localTimeInterval);
  }, [selectedTimezone]);

  const handleTimezoneChange = (value: string) => {
    setSelectedTimezone(value);
  };
  return (
    <>
      <Form layout='inline'>
        <Row>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              label='Time Zone'
              labelCol={isMobile ? undefined : { span: 10 }}
              wrapperCol={isMobile ? { span: 12 } : { span: 12 }}>
              <Select
                style={{ width: '10.6rem', marginRight: 80 }}
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                defaultValue={selectedTimezone}>
                {options.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              labelCol={isMobile ? undefined : { span: 12 }}
              wrapperCol={isMobile ? undefined : { span: 12 }}
              label='Local Time'>
              {localTime}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
