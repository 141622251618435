import { FC } from 'react';
import { Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';

import { useIsMobile } from 'helpers/hooks.helpers';

import { IRoundResult } from '../RoundReport.types';

export const RoundResult: FC<{ result: IRoundResult }> = ({ result }) => {
  const isMobile = useIsMobile();
  const isMobileSpan = isMobile ? 8 : 4;

  return (
    <Row wrap gutter={16}>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Result' description={result.result} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Started' description={result.started ? 'Yes' : 'No'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta title='Finished' description={result.finished ? 'Yes' : 'No'} />
        </Card>
      </Col>
      <Col span={isMobileSpan}>
        <Card style={{ marginBottom: 10 }}>
          <Meta
            title='Rollbacked'
            description={result.round_rollbacked ? 'Yes' : 'No'}
          />
        </Card>
      </Col>
    </Row>
  );
};
