import { combineReducers } from '@reduxjs/toolkit';

import gameUUIDConfigs from './gameUUIDConfigs/gameUUIDConfigs.slice';
import headerConfigs from './headerConfig/headerConfigs.slice';
import serverConfigs from './serverConfigs/serverConfigs.slice';
import appConfigs from './appConfigs/appConfigs.slice';

export const reducers = combineReducers({
  serverConfigs,
  appConfigs,
  headerConfigs,
  gameUUIDConfigs,
});
