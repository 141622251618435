import dayjs from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store.types';

import { IAppConfigs, ITimeRange } from './appConfigs.types';

const initialState: IAppConfigs = {
  isMenuSidebarOpen: false,
  isNotificationSidebarOpen: false,
  timeRange: {
    timeFrom: dayjs(Date.now()).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    timeTo: dayjs(Date.now()).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  },
};

export const appConfigsSlice = createSlice({
  name: 'appConfigs',
  initialState,
  reducers: {
    toggleMenuSidebar: (state, action: PayloadAction<boolean>) => {
      state.isMenuSidebarOpen = action.payload;
    },
    toggleNotificationSidebar: (state, action: PayloadAction<boolean>) => {
      state.isNotificationSidebarOpen = action.payload;
    },
    setTimeFrom: (state, action: PayloadAction<ITimeRange>) => {
      state.timeRange = action.payload;
    },

    resetAppConfigs: () => initialState,
  },
});

// ACTIONS
export const {
  toggleMenuSidebar,
  toggleNotificationSidebar,
  resetAppConfigs,
  setTimeFrom,
} = appConfigsSlice.actions;

// SELECTORS
export const selectIsMenuSidebarOpen = (state: RootState) =>
  state.appConfigs.isMenuSidebarOpen;
export const selectIsNotificationSidebarOpen = (state: RootState) =>
  state.appConfigs.isNotificationSidebarOpen;

export default appConfigsSlice.reducer;
