import { FC, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import { headerProjectsThunk } from 'redux/reducers/headerConfig/headerConfigs.thunk';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { setSelectedId } from 'redux/reducers/headerConfig/headerConfigs.slice';
import { setCurrentProjectPermission } from 'redux/reducers/serverConfigs/serverConfigs.slice';

type PropTypes = {
  isMobile: boolean;
};
export const HeaderProjects: FC<PropTypes> = ({ isMobile }) => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useSelector(
    (state: RootState) => state.headerConfigs,
  );
  const selectedPartnerId = useSelector(
    (state: RootState) => state.headerConfigs.partnerId,
  );
  const defaultValue = useSelector(
    (state: RootState) => state.headerConfigs.selectedId,
  );

  const userPermissions = useSelector(
    (state: RootState) => state.serverConfigs.user.tokenData.user.permission,
  );
  const handleChange = (value: number) => {
    dispatch(setSelectedId(value));
  };

  useEffect(() => {
    dispatch(headerProjectsThunk());
  }, [selectedPartnerId]);

  const options = data.map(item => ({
    value: item.id,
    label: item.name,
  }));
  useEffect(() => {
    if (!options?.length) return;
    const projectId = data?.find(e => e.id === defaultValue)?.id;
    handleChange(projectId || 0);
  }, [options]);

  useEffect(() => {
    const userCurrentProjectPermission = userPermissions.find(
      e => e.project.id === defaultValue,
    )?.role;
    if (userCurrentProjectPermission)
      dispatch(setCurrentProjectPermission(userCurrentProjectPermission));
  }, [defaultValue, userPermissions]);

  return (
    <>
      <Form layout='inline'>
        <Form.Item
          label={isMobile ? '' : 'Project'}
          labelCol={{ span: isMobile ? 9 : 8 }}>
          <Select
            style={{ width: isMobile ? '8rem' : '10.5rem' }}
            value={defaultValue}
            placeholder='Select a project'
            onChange={handleChange}
            loading={isLoading}
            options={options}
          />
        </Form.Item>
      </Form>
    </>
  );
};
