import { TableColumnProps } from 'antd';
import dayjs from 'dayjs';

import { IUserReportTable } from './UserReport.types';

export const UsersColumns: TableColumnProps<IUserReportTable>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Game Name',
    dataIndex: 'game_name',
    key: 'game_name',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Player ID',
    dataIndex: 'player_id',
    key: 'player_id',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Round ID',
    dataIndex: 'round_id',
    key: 'round_id',
  },
  {
    title: 'Session ID',
    dataIndex: 'session_id',
    key: 'session_id',
  },
  {
    title: 'Bet ID',
    dataIndex: 'bet_id',
    key: 'bet_id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'FreeRound ID',
    dataIndex: 'freeround_id',
    key: 'freeround_id',
  },
  {
    title: 'Client TRX ID',
    dataIndex: 'client_transaction_id',
    key: 'client_transaction_id',
  },
  {
    title: 'Rollbacked',
    dataIndex: 'rollbacked',
    key: 'rollbacked',
  },
  {
    title: 'Rollback ID',
    dataIndex: 'rollback_id',
    key: 'rollback_id',
  },
  {
    title: 'Player IP',
    dataIndex: 'player_ip',
    key: 'player_ip',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, data: IUserReportTable) =>
      dayjs(data.created_at).format('DD/MM/YYYY HH:MM'),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (_, data: IUserReportTable) =>
      dayjs(data.updated_at).format('DD/MM/YYYY HH:MM'),
  },
];

type RangeValueType<T> = T | null;
type ValueDate<T> = [RangeValueType<T>, RangeValueType<T>];

type Preset = {
  label: string;
  value: ValueDate<RangeValueType<dayjs.Dayjs>>;
};

export const rangePresets: Preset[] = [
  { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  {
    label: 'Yesterday',
    value: [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day'),
    ],
  },
  { label: 'Last 24 Hours', value: [dayjs().subtract(24, 'hour'), dayjs()] },
  {
    label: 'This Week',
    value: [dayjs().startOf('week'), dayjs().endOf('week')],
  },
  {
    label: 'Last Week',
    value: [
      dayjs().subtract(1, 'week').startOf('week'),
      dayjs().subtract(1, 'week').endOf('week'),
    ],
  },
  {
    label: 'This Month',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: 'Last Month',
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  },
];
