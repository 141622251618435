import { ColumnsType } from 'antd/es/table';

import { compareByAlph, replaceNonNumbers } from 'helpers/utils';

export const ProjectColumns: ColumnsType<{
  name: string;
  bet: string;
  win: string;
  ggr: string;
  rtp: string;
  total_players: string;
  total_bets: string;
  total_sessions: string;
  service_fee: string;
}> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => compareByAlph(a.name || '', b.name || ''),
  },
  {
    title: 'Bet',
    dataIndex: 'bet',
    key: 'bet',
    sorter: (a, b) => replaceNonNumbers(a.bet) - replaceNonNumbers(b.bet),
  },
  {
    title: 'Win',
    dataIndex: 'win',
    key: 'win',
    sorter: (a, b) => replaceNonNumbers(a.win) - replaceNonNumbers(b.win),
  },
  {
    title: 'GGR',
    dataIndex: 'ggr',
    key: 'ggr',
    sorter: (a, b) => replaceNonNumbers(a.ggr) - replaceNonNumbers(b.ggr),
  },
  {
    title: 'RTP',
    dataIndex: 'rtp',
    key: 'rtp',
    sorter: (a, b) => replaceNonNumbers(a.rtp) - replaceNonNumbers(b.rtp),
  },
  {
    title: 'Players',
    dataIndex: 'total_players',
    key: 'total_players',
    sorter: (a, b) =>
      replaceNonNumbers(a.total_players) - replaceNonNumbers(b.total_players),
  },
  {
    title: 'Sessions',
    dataIndex: 'total_sessions',
    key: 'total_sessions',
    sorter: (a, b) =>
      replaceNonNumbers(a.total_sessions) - replaceNonNumbers(b.total_sessions),
  },
  {
    title: 'Service Fee',
    dataIndex: 'service_fee',
    key: 'service_fee',
    sorter: (a, b) =>
      replaceNonNumbers(a.service_fee) - replaceNonNumbers(b.service_fee),
  },
];

export const UsersColumns: ColumnsType<{
  name: string;
  bet: string;
  win: string;
  ggr: string;
  rtp: string;
  total_players: string;
  total_bets: string;
  total_sessions: string;
  service_fee: string;
}> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => compareByAlph(a.name || '', b.name || ''),
  },
  {
    title: 'Bet',
    dataIndex: 'bet',
    key: 'bet',
    sorter: (a, b) => replaceNonNumbers(a.bet) - replaceNonNumbers(b.bet),
  },
  {
    title: 'Win',
    dataIndex: 'win',
    key: 'win',
    sorter: (a, b) => replaceNonNumbers(a.win) - replaceNonNumbers(b.win),
  },
  {
    title: 'GGR',
    dataIndex: 'ggr',
    key: 'ggr',
    sorter: (a, b) => replaceNonNumbers(a.ggr) - replaceNonNumbers(b.ggr),
  },
  {
    title: 'RTP',
    dataIndex: 'rtp',
    key: 'rtp',
    sorter: (a, b) => replaceNonNumbers(a.rtp) - replaceNonNumbers(b.rtp),
  },
  {
    title: 'Players',
    dataIndex: 'total_players',
    key: 'total_players',
    sorter: (a, b) =>
      replaceNonNumbers(a.total_players) - replaceNonNumbers(b.total_players),
  },
  {
    title: 'Total bets',
    dataIndex: 'total_bets',
    key: 'total_bets',
    sorter: (a, b) =>
      replaceNonNumbers(a.total_bets) - replaceNonNumbers(b.total_bets),
  },
  {
    title: 'Sessions',
    dataIndex: 'total_sessions',
    key: 'total_sessions',
    sorter: (a, b) =>
      replaceNonNumbers(a.total_sessions) - replaceNonNumbers(b.total_sessions),
  },
  {
    title: 'Service Fee',
    dataIndex: 'service_fee',
    key: 'service_fee',
    sorter: (a, b) =>
      replaceNonNumbers(a.service_fee) - replaceNonNumbers(b.service_fee),
  },
];
