/* eslint-disable no-continue */
import { TableColumnProps } from 'antd';
import dayjs from 'dayjs';

import { compareByAlph, replaceNonNumbers } from 'helpers/utils';

import { IRoundTable } from './RoundReport.types';

export const RoundReportColumns: TableColumnProps<IRoundTable>[] = [
  {
    title: 'Bet id',
    dataIndex: 'bet_id',
    key: 'bet_id',
    sorter: (a, b) => replaceNonNumbers(a.bet_id) - replaceNonNumbers(b.bet_id),
  },
  {
    title: 'Round id',
    dataIndex: 'round_id',
    key: 'round_id',
    sorter: (a, b) =>
      replaceNonNumbers(a.round_id) - replaceNonNumbers(b.round_id),
  },
  {
    title: 'User id',
    dataIndex: 'user_id',
    key: 'user_id',
    sorter: (a, b) =>
      replaceNonNumbers(a.user_id) - replaceNonNumbers(b.user_id),
  },
  {
    title: 'Amount',
    dataIndex: 'bet_amount',
    key: 'bet_amount',
    sorter: (a, b) =>
      replaceNonNumbers(a.bet_amount) - replaceNonNumbers(b.bet_amount),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Win odd',
    dataIndex: 'cashout_odd',
    key: 'cashout_odd',
    sorter: (a, b) =>
      replaceNonNumbers(a.cashout_odd) - replaceNonNumbers(b.cashout_odd),
  },
  {
    title: 'Coefficient',
    dataIndex: 'coefficient',
    key: 'coefficient',
    sorter: (a, b) =>
      replaceNonNumbers(a.coefficient) - replaceNonNumbers(b.coefficient),
  },
  {
    title: 'Result',
    dataIndex: 'plinko_indexes',
    key: 'plinko_indexes',
    render: (e: string) => {
      const parsed = JSON.parse(e);
      return (
        <div
          style={{
            background: 'blue',
            color: 'white',
            width: '20px',
            height: '20px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
          }}>
          {parsed[0].result_index}
        </div>
      );
    },
  },
  {
    title: 'Bet',
    dataIndex: 'bet',
    key: 'bet',
    render: (e: string) => {
      const parsed = JSON.parse(e);
      return (
        <div
          style={{
            display: 'flex',
            gap: '1px',
            justifyContent: 'normal',
          }}>
          {parsed.map((betNumber: number) => (
            <div
              style={{
                background: 'black',
                color: 'white',
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {betNumber}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    title: 'Won numbers',
    dataIndex: 'won_numbers',
    key: 'won_numbers',
    render: (e: string) => {
      const parsed = JSON.parse(e);
      return (
        <div
          style={{
            display: 'flex',
            gap: '1px',
            justifyContent: 'normal',
          }}>
          {parsed[0].result.map((betNumber: number) => (
            <div
              style={{
                background: 'black',
                color: 'white',
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {betNumber}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    title: 'Risk',
    dataIndex: 'risk',
    key: 'risk',
    sorter: (a, b) => compareByAlph(a.risk || '', b.risk || ''),
  },
  {
    title: 'Rows',
    dataIndex: 'rows',
    key: 'rows',
    sorter: (a, b) => replaceNonNumbers(a.rows) - replaceNonNumbers(b.rows),
  },
  {
    title: 'Won amount',
    dataIndex: 'won_amount',
    key: 'won_amount',
    sorter: (a, b) =>
      replaceNonNumbers(a.won_amount) - replaceNonNumbers(b.won_amount),
  },
  {
    title: 'Is won',
    dataIndex: 'won',
    key: 'won',
    render: (is_won: boolean) => (is_won ? 'Yes' : 'No'),
    sorter: (a, b) => replaceNonNumbers(a.won) - replaceNonNumbers(b.won),
  },
  {
    title: 'Won billed',
    dataIndex: 'won_billed',
    key: 'won_billed',
    render: (is_won_billed: boolean) => (is_won_billed ? 'Yes' : 'No'),
    sorter: (a, b) =>
      replaceNonNumbers(a.won_billed) - replaceNonNumbers(b.won_billed),
  },
  {
    title: 'Bet billed',
    dataIndex: 'billed',
    key: 'billed',
    render: (is_billed: boolean) => (is_billed ? 'Yes' : 'No'),
    sorter: (a, b) => replaceNonNumbers(a.billed) - replaceNonNumbers(b.billed),
  },
  {
    title: 'Canceled',
    dataIndex: 'canceled',
    key: 'canceled',
    render: (is_canceled: boolean) => (is_canceled ? 'Yes' : 'No'),
    sorter: (a, b) =>
      replaceNonNumbers(a.canceled) - replaceNonNumbers(b.canceled),
  },
  {
    title: 'Cr. at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, data: IRoundTable) =>
      dayjs(data.created_at).format('DD/MM/YYYY HH:mm'),
    sorter: (a, b) => dayjs(a.created_at).diff(b.created_at),
  },
  {
    title: 'Client bet id',
    dataIndex: 'bet_trx_id',
    key: 'bet_trx_id',
  },
  {
    title: 'Client win id',
    dataIndex: 'win_trx_id',
    key: 'win_trx_id',
  },
  {
    title: 'Client rollback id',
    dataIndex: 'rollback_trx_id',
    key: 'rollback_trx_id',
  },
  {
    title: 'Demo',
    dataIndex: 'is_demo',
    key: 'is_demo',
    render: (is_demo: number) => (is_demo ? 'Yes' : 'No'),
    sorter: (a, b) =>
      replaceNonNumbers(a.is_demo) - replaceNonNumbers(b.is_demo),
  },
];

export const createTableColumnsFromRoundReportsData = (
  data: Record<string | number, string | number | boolean>,
): TableColumnProps<IRoundTable>[] => {
  const columns: TableColumnProps<IRoundTable>[] = [];
  const keys = RoundReportColumns.map(e => e.key);
  const addedKeys: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (!key || typeof key !== 'string' || typeof data[key] === 'undefined')
      continue;

    addedKeys.push(key);

    const keyData = RoundReportColumns.find(e => e.key === key);
    if (!keyData) continue;
    columns.push(keyData);
  }

  return columns;
};

export const ROUND_STATS_PERMISSIONS_INCLUDE = [
  'ADMIN',
  'SUPER_ADMIN',
  'PARTNER',
  'RISK_MANAGER',
];
