// import { Divider, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
// import { Chart } from 'react-google-charts';
// import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RootState } from 'redux/store.types';

// import { DashboardForm } from '../Dashboard/-Form';
// import { IDashboardFiltersForm } from '../Dashboard';

// import { IChartDataItem } from './Analytics.types';

const ANALYTICS_STATS_PERMISSIONS_INCLUDE = [
  'ADMIN',
  'SUPER_ADMIN',
  'PARTNER',
  'RISK_MANAGER',
];

export const Analytics: FC = () => {
  const [isAvailable, setIsAvailable] = useState(true);

  const userCurrentProjectPermission = useSelector(
    (state: RootState) => state.serverConfigs.user.currentProjectPermission,
  );

  useEffect(() => {
    setIsAvailable(
      ANALYTICS_STATS_PERMISSIONS_INCLUDE.includes(
        userCurrentProjectPermission.role,
      ),
    );
  }, [userCurrentProjectPermission]);

  useEffect(() => {
    if (!isAvailable) {
      toast.error('Access denied.');
    }
  }, [isAvailable]);

  if (!isAvailable) {
    return <></>;
  }

  return (
    <>
      <iframe
        src='https://bi.abcdabra.com/public/dashboard/d2db646f-2860-4395-afdc-892de971e8e0'
        title='Dashboard'
        frameBorder='0'
        allowFullScreen
        width='100%'
        height='100%'
      />
    </>
  );
};
