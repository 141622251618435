import classNames from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectIsLogout } from 'redux/reducers/serverConfigs/serverConfigs.slice';

import { useIsMobile } from '../../../helpers/hooks.helpers';
import { selectIsMenuSidebarOpen } from '../../../redux/reducers/appConfigs/appConfigs.slice';

import classes from './ABCDLogo.module.scss';

const ABCDLogo: FC = () => {
  const isSidebarOpen = useSelector(selectIsMenuSidebarOpen);
  const isMobile = useIsMobile();
  const isLogout = useSelector(selectIsLogout);

  return (
    <Link to='/' className={classes.logoLink}>
      <div
        className={classNames(classes.logoContainer, 'transition', {
          [classes.logo]: isSidebarOpen && !isMobile,
          [classes.logoMin]: !isSidebarOpen || isMobile || !isLogout,
        })}
      />
    </Link>
  );
};

export default ABCDLogo;
