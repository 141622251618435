import { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import { useIsMobile } from 'helpers/hooks.helpers';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { setTimeFrom } from 'redux/reducers/appConfigs/appConfigs.slice';
import { rangePresets } from 'components/Routes/Reports/UserReport/UserReport.const';

import { IDashboardFiltersForm } from '../Dashboard.types';

import classes from './Dashboard-Form.module.scss';

type PropTypes = {
  dashboardHandleMutation: (data: IDashboardFiltersForm) => void;
};
export const DashboardForm: FC<PropTypes> = ({ dashboardHandleMutation }) => {
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const { RangePicker } = DatePicker;
  const dispatch = useAppDispatch();

  const { timeFrom, timeTo } = useSelector(
    (state: RootState) => state.appConfigs.timeRange,
  );
  const currencyData = useSelector(
    (state: RootState) => state.headerConfigs.currencies,
  );
  const selectedCurrency = useSelector(
    (state: RootState) => state.headerConfigs.selectedCurrency,
  );
  const projectId = useSelector(
    (state: RootState) => state.headerConfigs.selectedId,
  );

  const [timeRange, setTimeRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([dayjs(timeFrom), dayjs(timeTo)]);

  const [timeValues, setTimeValues] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([dayjs(Date.now()).startOf('day'), dayjs(Date.now()).endOf('day')]);

  const handleRangePickerChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  ) => {
    setTimeRange(dates);
    dispatch(
      setTimeFrom({
        timeFrom: dates[0]?.format('YYYY-MM-DD HH:mm:ss') || '',
        timeTo: dates[1]?.format('YYYY-MM-DD HH:mm:ss') || '',
      }),
    );
  };

  const handleDatePickerChange = (date: dayjs.Dayjs | null, index: number) => {
    const newRange = [...timeRange];
    newRange[index] = date;
    setTimeRange(newRange as [dayjs.Dayjs | null, dayjs.Dayjs | null]);
  };

  const handleTimeChange = (time: dayjs.Dayjs | null, index: number) => {
    const newTimes = [...timeValues];
    newTimes[index] = time;
    setTimeValues(newTimes as [dayjs.Dayjs | null, dayjs.Dayjs | null]);
  };

  const onFinish = () => {
    let fromTime = timeRange[0];
    let toTime = timeRange[1];

    if (fromTime && timeValues[0]) {
      fromTime = fromTime
        .set('hour', timeValues[0].hour())
        .set('minute', timeValues[0].minute())
        .set('second', timeValues[0].second());
    }

    if (toTime && timeValues[1]) {
      toTime = toTime
        .set('hour', timeValues[1].hour())
        .set('minute', timeValues[1].minute())
        .set('second', timeValues[1].second());
    }

    if (fromTime && toTime) {
      const data = {
        timeFrom: fromTime.format('YYYY-MM-DD HH:mm:ss'),
        timeTo: toTime.format('YYYY-MM-DD HH:mm:ss'),
        currency: currencyData[selectedCurrency],
      };
      dashboardHandleMutation(data);
    }
  };

  useEffect(() => {
    if (projectId) form.submit();
  }, [projectId]);

  const handleRangeChange = (value: string) => {
    const selectedPreset = rangePresets.find(preset => preset.label === value);
    if (selectedPreset) {
      setTimeRange(selectedPreset.value);
      dispatch(
        setTimeFrom({
          timeFrom:
            selectedPreset.value[0]?.format('YYYY-MM-DD HH:mm:ss') || '',
          timeTo: selectedPreset.value[1]?.format('YYYY-MM-DD HH:mm:ss') || '',
        }),
      );
    }
  };

  return (
    <Form
      initialValues={{
        timeFrom,
        timeTo,
      }}
      className={classes.dashboardForm}
      onFinish={onFinish}
      form={form}>
      <Row gutter={[12, 2]}>
        <Col span={isMobile ? 16 : 8}>
          {isMobile ? (
            <Form.Item name={['timeTo', 'timeFrom']}>
              <Row gutter={[12, 2]}>
                <Col span={12}>
                  <DatePicker
                    size='large'
                    onChange={date => handleDatePickerChange(date, 0)}
                    defaultValue={dayjs().startOf('day')}
                    placeholder='Start date'
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    popupStyle={{ left: '17px' }}
                    size='large'
                    onChange={date => handleDatePickerChange(date, 1)}
                    defaultValue={dayjs().endOf('day')}
                    placeholder='End date'
                  />
                </Col>
              </Row>
            </Form.Item>
          ) : (
            <Form.Item name={['timeTo', 'timeFrom']}>
              <RangePicker
                width='10rem'
                showTime={{ format: 'HH:mm:ss' }}
                size='large'
                tabIndex={0}
                defaultValue={[dayjs().startOf('day'), dayjs().endOf('day')]}
                onChange={handleRangePickerChange}
                presets={[...rangePresets]}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Button
            style={{ height: 37, width: '100%' }}
            htmlType='submit'
            type='primary'>
            Search
          </Button>
        </Col>
      </Row>
      {isMobile ? (
        <Row gutter={[12, 2]}>
          <Col span={8}>
            <TimePicker
              defaultValue={timeValues[0]}
              format='HH:mm:ss'
              onChange={time => handleTimeChange(time, 0)}
              placeholder='Time from'
              size='large'
            />
          </Col>
          <Col span={8}>
            <TimePicker
              defaultValue={timeValues[1]}
              format='HH:mm:ss'
              onChange={time => handleTimeChange(time, 1)}
              placeholder='Time to'
              size='large'
            />
          </Col>
          <Col span={8}>
            <Select
              onChange={handleRangeChange}
              size='large'
              style={{ width: '100%' }}
              placeholder='Range'>
              {rangePresets.map(preset => (
                <Select.Option value={preset.label}>
                  {preset.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
};
