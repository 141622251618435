import React from 'react';
import { useLocation } from 'react-router-dom';

export function getIsDevelopment() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function addOpacity(rgbString: string, opacity: string) {
  return `${rgbString.split(')')[0]},${opacity})`;
}

export const wait = (ms = 3000) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const compareByAlph = (a: string, b: string) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const replaceNonNumbers = (number: string | number): number => {
  return Number(String(number).replace(/\D/g, ''));
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
