import { Col, Divider, Row, Table } from 'antd';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { RootState } from 'redux/store.types';
import { gameUUIDThunk } from 'redux/reducers/gameUUIDConfigs/gameUUIDConfigs.thunk';

import { RoundForm } from './-Form';
import { RoundStatistics } from './-Statistics';
import { useRoundMutations } from './hooks/useRoundMutation';
import {
  ROUND_STATS_PERMISSIONS_INCLUDE,
  createTableColumnsFromRoundReportsData,
} from './RoundReport.const';
import { IRoundFiltersForm, IRoundResult } from './RoundReport.types';
import { RoundResult } from './-Result';

export const RoundReport: FC = () => {
  const projectId = useSelector(
    (state: RootState) => state.headerConfigs.selectedId,
  );
  const userCurrentProjectPermission = useSelector(
    (state: RootState) => state.serverConfigs.user.currentProjectPermission,
  );

  const { roundMutationData } = useRoundMutations();
  const roundResultData: IRoundResult =
    roundMutationData.data?.data?.roundResult;
  const generalReportData = roundMutationData.data?.data?.stats;
  const totalWin = generalReportData?.win;
  const totalBet = generalReportData?.bet;
  const ggr = generalReportData?.ggr;
  const rtp = generalReportData?.rtp;
  const totalSessions = generalReportData?.total_sessions;
  const totalPlayers = generalReportData?.total_players;

  const dispatch = useAppDispatch();

  const gameUUIDData = useSelector((state: RootState) => state.gameUUIDConfigs);
  useEffect(() => {
    dispatch(gameUUIDThunk(projectId));
  }, [projectId]);

  const allTotal = roundMutationData.data?.data?.stats?.length;
  const handleMutation = (data: IRoundFiltersForm) => {
    roundMutationData.mutate(data);
  };
  const tableData = roundMutationData.data?.data?.roundBets;

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <RoundForm
            roundMutationHandler={handleMutation}
            gameUUIDOptions={gameUUIDData}
          />
        </Col>
        <Col span={24}>
          {ROUND_STATS_PERMISSIONS_INCLUDE.includes(
            userCurrentProjectPermission?.role || '',
          ) && (
            <RoundStatistics
              totalWin={totalWin}
              totalBet={totalBet}
              ggr={ggr}
              rtp={rtp}
              totalSessions={totalSessions}
              totalPlayers={totalPlayers}
            />
          )}
        </Col>
        <Col span={24}>
          {roundResultData && <RoundResult result={roundResultData} />}
        </Col>
      </Row>
      <br />
      <Divider dashed orientation='left'>
        Game Report
      </Divider>
      <Table
        dataSource={tableData || []}
        style={{ marginTop: 50 }}
        rowKey='id'
        loading={roundMutationData.isLoading}
        columns={createTableColumnsFromRoundReportsData(tableData?.[0] || {})}
        scroll={{ x: true }}
        bordered
        pagination={{
          pageSize: 100,
          position: ['bottomCenter'],
          total: allTotal,
        }}
      />
    </>
  );
};
