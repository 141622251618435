import axios from 'axios';

import { baseURL } from 'api/baseUrl';

export const headerService = {
  async getProjects() {
    const response = await axios.get(`${baseURL}/api/v1/projects`);
    const data = [{ id: 0, name: 'All Projects' }, ...response.data];
    return data;
  },
  async getCurrencies() {
    const response = await axios.get(`${baseURL}/api/v1/currencies`);
    return response.data;
  },
};
