import { createAsyncThunk } from '@reduxjs/toolkit';

import { bodyService } from 'services/body.service';

export const gameUUIDThunk = createAsyncThunk(
  'gameUUID',
  async (projectId: string | number) => {
    const response = await bodyService.getGameUUID(projectId);
    return response;
  },
);
