import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Popover, Row, Select } from 'antd';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { useIsMobile } from 'helpers/hooks.helpers';
import { IGamesUUID } from 'redux/reducers/gameUUIDConfigs/gameUUIDConfigs.types';
import { useQuery } from 'helpers/utils';
import { RootState } from 'redux/store.types';

import { IRoundFiltersForm } from '../RoundReport.types';

import classes from './Round-Form.module.scss';

type PropTypes = {
  roundMutationHandler: (data: IRoundFiltersForm) => void;
  gameUUIDOptions: IGamesUUID;
};

export const RoundForm: FC<PropTypes> = ({
  roundMutationHandler,
  gameUUIDOptions,
}) => {
  const isMobile = useIsMobile();
  const query = useQuery();

  const projectId = useSelector(
    (state: RootState) => state.headerConfigs.selectedId,
  );

  const { data: projectData } = useSelector(
    (state: RootState) => state.headerConfigs,
  );

  const [form] = Form.useForm();

  const copyLinkRef = () => {
    let formRefLink = `${window.location.origin}${window.location.pathname}?`;

    const formProjectName = projectData.find(e => e.id === projectId)?.name;
    if (formProjectName) formRefLink += `project_name=${formProjectName}&`;

    const formGameId = form.getFieldValue('game_uuid');
    if (formGameId) {
      const gameName = gameUUIDOptions.data.find(
        e => e.game_uuid === formGameId,
      )?.name;
      formRefLink += `game=${gameName}&`;
    }

    const formBetId = form.getFieldValue('bet_id');
    if (formBetId) formRefLink += `bet_id=${formBetId}&`;

    const formRoundId = form.getFieldValue('round_id');
    if (formRoundId) formRefLink += `round_id=${formRoundId}&`;

    const formUserId = form.getFieldValue('user_id');
    if (formUserId) formRefLink += `user_id=${formUserId}&`;

    navigator.clipboard.writeText(formRefLink);
  };

  const onFinish = (data: IRoundFiltersForm) => {
    roundMutationHandler(data);
  };

  const [gameUuid, setGameUuid] = useState('');

  const onDownloadClick = () => {
    form.validateFields().then(values => {
      const dataWithFile = {
        ...values,
        file: true,
      };
      roundMutationHandler(dataWithFile);
    });
  };

  useEffect(() => {
    if (gameUuid && projectId) {
      form.submit();
    }
  }, [gameUuid, projectId]);

  useEffect(() => {
    const queryGameName = query.get('game');
    const fromQueryGameUuid = gameUUIDOptions.data.find(
      e => e.name.toLowerCase() === queryGameName?.toLowerCase(),
    );
    if (fromQueryGameUuid?.game_uuid) {
      setGameUuid(fromQueryGameUuid.game_uuid);
      form.setFieldValue('game_uuid', fromQueryGameUuid.game_uuid);
    }

    const queryRoundId = query.get('round_id');
    if (queryRoundId) form.setFieldValue('round_id', queryRoundId);

    const queryUserId = query.get('user_id');
    if (queryUserId) form.setFieldValue('user_id', queryUserId);

    const queryBetId = query.get('bet_id');
    if (queryBetId) form.setFieldValue('bet_id', queryBetId);
  }, [gameUUIDOptions, query]);

  return (
    <Form className={classes.form} onFinish={onFinish} form={form}>
      <Row gutter={[16, 4]}>
        <Col span={isMobile ? 8 : 2}>
          <Form.Item
            name='game_uuid'
            rules={[{ required: true, message: 'Please select game!' }]}>
            <Select
              size='small'
              placeholder='Select game'
              onChange={(val: string) => setGameUuid(val)}
              style={{ height: 40 }}
              options={gameUUIDOptions.data.map(item => ({
                value: item.game_uuid,
                label: item.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Form.Item name='round_id'>
            <Input placeholder='Round id' style={{ height: 40 }} />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Form.Item name='user_id'>
            <Input placeholder='User id' style={{ height: 40 }} />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Form.Item name='bet_id'>
            <Input placeholder='Bet id' style={{ height: 40 }} />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Button
            style={{ height: 40, width: '100%' }}
            htmlType='submit'
            type='primary'>
            Search
          </Button>
        </Col>
        <Col span={isMobile ? 8 : 2}>
          <Popover content='Link copied to clipboard' trigger='click'>
            <Button
              style={{ height: 40, width: '100%' }}
              type='primary'
              onClick={copyLinkRef}
              icon={<CopyOutlined />}
            />
          </Popover>
        </Col>
        <Col span={isMobile ? 16 : 4} offset={isMobile ? 0 : 8}>
          <Button
            onClick={onDownloadClick}
            style={{ height: 40, width: '100%' }}
            type='primary'
            icon={<DownloadOutlined />}>
            Download Report
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
