import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { IErrorMessage, RootState } from 'redux/store.types';
import { baseURL } from 'api/baseUrl';

import { IRoundFiltersForm } from '../RoundReport.types';

export const useRoundMutations = () => {
  const id = useSelector((state: RootState) => state.headerConfigs.selectedId);

  const roundMutationData = useMutation({
    mutationFn: (data: IRoundFiltersForm) => {
      return axios.post(`${baseURL}/api/v1/${id}/round-report`, data);
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.error || t('Something went wrong'));
    },
  });

  return { roundMutationData };
};
