import axios from 'axios';

import { baseURL } from 'api/baseUrl';

export const bodyService = {
  async getGameUUID(projectId: string | number) {
    const response = await axios.get(
      `${baseURL}/api/v1/${projectId}/available-games`,
    );
    return response.data;
  },
};
