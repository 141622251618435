import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import i18n from 'assets/translations';
// import { RootState } from 'redux/store.types';

import { IGMTOption, IHeader } from './headerConfigs.types';
import {
  headerCurrenciesThunk,
  headerProjectsThunk,
} from './headerConfigs.thunk';

const initialState: IHeader = {
  data: [
    {
      id: null,
      name: '',
      partner: {
        id: null,
        name: '',
      },
      partner_id: null,
      permission: [],
      region: {
        id: null,
        display_name: '',
      },
      region_id: null,
      isLoading: false,
    },
  ],
  projects: [],
  error: null,
  isLoading: false,
  partnerId: Number(localStorage.getItem('partnerId')) || null,
  selectedId: Number(localStorage.getItem('selectedId')) || 0,
  currencies: ['USD'],
  selectedCurrency: parseInt(
    localStorage.getItem('selectedCurrency') || '0',
    10,
  ),
  gmtOptions: [],
};

export const headerConfigsSlice = createSlice({
  name: 'headerConfigs',
  initialState,
  reducers: {
    setPartnerId: (state, action) => {
      state.partnerId = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
      localStorage.setItem('selectedId', action.payload);
    },
    setGMTOptions: (state, action: PayloadAction<IGMTOption[]>) => {
      state.gmtOptions = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      localStorage.setItem('selectedCurrency', action.payload.toString());
      state.selectedCurrency = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(headerProjectsThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(headerProjectsThunk.rejected, state => {
        state.isLoading = false;
        toast.error(i18n.t('You have incorrect field!'));
      })
      .addCase(headerProjectsThunk.fulfilled, (state, action) => {
        const selectedPartnerId = state.partnerId;
        state.data = action.payload.filter(
          (item: any) => item.partner_id === selectedPartnerId || item.id === 0,
        );
        state.isLoading = false;
      })
      .addCase(headerCurrenciesThunk.rejected, state => {
        state.isLoading = false;
        toast.error(i18n.t('You have incorrect field!'));
      })
      .addCase(headerCurrenciesThunk.fulfilled, (state, action) => {
        state.currencies = Array.from(new Set(action.payload));
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedId,
  setGMTOptions,
  setSelectedCurrency,
  setPartnerId,
} = headerConfigsSlice.actions;

// SELECTORS
// export const selectProjectId = (state: RootState) =>
//   state.headerConfigs.data.filter(item => item.id === 2);

export default headerConfigsSlice.reducer;
