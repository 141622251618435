import { Navigate } from 'react-router-dom';
import {
  ApartmentOutlined,
  // BellOutlined,
  CalendarOutlined,
  HomeOutlined,
  // TransactionOutlined,
  // UserOutlined,
  FundOutlined,
} from '@ant-design/icons';

import AuthLayout from '../Layouts/AuthLayout/AuthLayout';
import i18n from '../../assets/translations';

// import ChangePassword from './ChangePassword/ChangePassword';
import Login from './Login/Login';
import { IRoutePath, IMenuRoute } from './routes.types';
import { PasswordChangeNeedRestrict } from './routes.restricts';
// import Transactions from './Transactions/Transactions';
// import Daily from './Limits/Daily/Daily';
import { Dashboard } from './Dashboard';
import { Analytics } from './Analytics';
// import { BetReport } from './Reports/BetReport';
// import { UserReport } from './Reports/UserReport';
// import { TransactionReport } from './Reports/TransactionReport';
import { RoundReport } from './Reports/RoundReport';

import type { RouteObject } from 'react-router-dom';

export const LOGOUT_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='/login' />,
      },
      {
        path: '/login',
        element: (
          <PasswordChangeNeedRestrict isPasswordPage={false}>
            <Login />
          </PasswordChangeNeedRestrict>
        ),
      },
      // {
      //   path: '/create-password',
      //   element: (
      //     <PasswordChangeNeedRestrict isPasswordPage>
      //       <ChangePassword />
      //     </PasswordChangeNeedRestrict>
      //   ),
      // },
      {
        path: '*',
        element: <Navigate to='/login' />,
      },
    ],
  },
];

export const MENU_ROUTES: IMenuRoute[] = [
  {
    path: IRoutePath.dashboard,
    icon: <HomeOutlined />,
    label: i18n.t('Dashboard'),
    element: <Dashboard />,
  },
  // {
  //   path: IRoutePath.transactions,
  //   icon: <TransactionOutlined />,
  //   label: i18n.t('Transactions'),
  //   element: <Transactions />,
  // },
  {
    icon: <CalendarOutlined />,
    label: i18n.t('Reports'),
    children: [
      // {
      //   path: IRoutePath.reports_bet,
      //   icon: <BellOutlined />,
      //   label: i18n.t('Bet Report'),
      //   element: <BetReport />,
      // },
      // {
      //   path: IRoutePath.reports_user,
      //   icon: <UserOutlined />,
      //   label: i18n.t('User Report'),
      //   element: <UserReport />,
      // },
      // {
      //   path: IRoutePath.reports_transaction,
      //   icon: <TransactionOutlined />,
      //   label: i18n.t('Transaction Report'),
      //   element: <TransactionReport />,
      // },
      {
        path: IRoutePath.reports_game,
        icon: <ApartmentOutlined />,
        label: i18n.t('Game Report'),
        element: <RoundReport />,
      },
    ],
  },
  {
    path: IRoutePath.analytics,
    icon: <FundOutlined />,
    label: i18n.t('Analytics'),
    element: <Analytics />,
  },
  // {
  //   aclPath: IAclPath.reports,
  //   icon: <GifOutlined />,
  //   label: i18n.t('Reports'),
  //   children: [
  //     {
  //       path: IRoutePath.reports_sports,
  //       aclPath: IAclPath.reports_sports,
  //       icon: <QqOutlined />,
  //       label: i18n.t('Sport'),
  //       element: <SportReports />,
  //     },
  //     {
  //       path: IRoutePath.reports_casino,
  //       aclPath: IAclPath.reports_casino,
  //       icon: <AimOutlined />,
  //       label: i18n.t('Casino'),
  //       element: <CasinoReports />,
  //     },
  //   ],
  // },
  // {
  //   // aclPath: IAclPath.admin,
  //   icon: <DeploymentUnitOutlined />,
  //   label: i18n.t('Admin Panel'),
  //   children: [
  //     {
  //       path: IRoutePath.admin_for_users,
  //       icon: <TeamOutlined />,
  //       label: i18n.t('Users'),
  //       element: <UsersForAdmin />,
  //     },
  //   ],
  // },
  // {
  //   icon: <SettingOutlined />,
  //   label: i18n.t('Settings'),
  //   children: [
  //     {
  //       path: IRoutePath.setting_account,
  //       icon: <UserOutlined />,
  //       label: i18n.t('Account'),
  //       element: <Settings />,
  //     },
  //   ],
  // },
];
