import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import i18n from 'assets/translations';
import { IAclPath } from 'components/Routes/routes.types';
import { parseJwt } from 'helpers/utils';

import { RootState } from '../../store.types';

import { IServerConfigs } from './serverConfigs.types';
import {
  changeProfileThunk,
  loginByTokenThunk,
  loginThunk,
} from './serverConfigs.thunks';

const initialState: IServerConfigs = {
  isConnected: false,
  isLoading: false,
  isProfileChangeLoading: false,
  isNewProfile: false,
  user: {
    id: null,
    acl: [],
    name: '',
    created_at: '',
    email: '',
    is_active: 1,
    is_sp_reset: 0,
    is_twofa_enabled: 0,
    locale: 'EN',
    role: 'USER',
    sp_updated_at: '',
    timezone: '',
    token: '',
    updated_at: '',
    username: '',
    meta: {
      last_action_at: '',
    },
    partner: {
      id: 0,
      name: '',
    },
    tokenData: {
      user: {
        permission: [],
        partners: [],
        partner: {
          id: 0,
          name: '',
        },
      },
    },
    currentProjectPermission: {
      role: 'NONE',
    },
  },
};

export const serverConfigsSlice = createSlice({
  name: 'serverConfigs',
  initialState,
  reducers: {
    setIsConnected: state => {
      state.isConnected = true;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    logout: () => {
      localStorage.removeItem('token');
      return { ...initialState, isConnected: true };
    },
    toggleTFA: state => {
      state.user.is_twofa_enabled = 1;
    },
    setCurrentProjectPermission: (
      state,
      action: PayloadAction<IServerConfigs['user']['currentProjectPermission']>,
    ) => {
      state.user.currentProjectPermission = action.payload;
    },
    resetServerConfigs: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(loginThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        const { payload, meta } = action;

        state.isLoading = false;
        const tokenData = parseJwt(action.payload.token);
        state.user = {
          ...action.payload,
          tokenData,
          currentProjectPermission: {
            role: 'NONE',
          },
        };

        if (meta.arg.isRemember) {
          localStorage.setItem('token', payload.token);
        }
      })
      .addCase(loginThunk.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error || i18n.t('You have incorrect field!'));
      })
      .addCase(loginByTokenThunk.fulfilled, (state, action) => {
        state.isConnected = true;
        const tokenData = parseJwt(action.payload.token);
        state.user = {
          ...action.payload,
          tokenData,
          currentProjectPermission: {
            role: 'NONE',
          },
        };
      })
      .addCase(loginByTokenThunk.rejected, state => {
        state.isConnected = true;
        localStorage.removeItem('token');
      })
      .addCase(changeProfileThunk.pending, state => {
        state.isProfileChangeLoading = true;
      })
      .addCase(changeProfileThunk.fulfilled, state => {
        state.isProfileChangeLoading = false;
        state.user.is_sp_reset = 0;
        toast.success(i18n.t('Your profile has successfully updated'));
      })
      .addCase(changeProfileThunk.rejected, state => {
        state.isProfileChangeLoading = false;
        toast.error(i18n.t('Something went wrong'));
      });
  },
});

// ACTIONS
export const {
  setIsConnected,
  setIsLoading,
  toggleTFA,
  logout,
  resetServerConfigs,
  setCurrentProjectPermission,
} = serverConfigsSlice.actions;

// SELECTORS
export const selectIsConnected = (state: RootState) =>
  state.serverConfigs.isConnected;
export const selectUserAcl = (state: RootState) => state.serverConfigs.user.acl;
export const selectIsTFAConnected = (state: RootState) =>
  !!state.serverConfigs.user.is_twofa_enabled;
export const selectIsAclExist = (state: RootState, aclPath: IAclPath) =>
  state.serverConfigs.user.acl.includes(aclPath);
export const selectIsServerConfigsLoading = (state: RootState) =>
  state.serverConfigs.isLoading;
export const selectIsProfileChangeLoading = (state: RootState) =>
  state.serverConfigs.isProfileChangeLoading;
export const selectIsNewProfile = (state: RootState) =>
  state.serverConfigs.isNewProfile;
export const selectIsAuth = (state: RootState) =>
  !!state.serverConfigs.user.name && !state.serverConfigs.user.is_sp_reset;
export const selectIsPasswordChangeRequired = (state: RootState) =>
  !!state.serverConfigs.user.is_sp_reset;
export const selectIsLogout = (state: RootState) =>
  !!state.serverConfigs.user.name;

export default serverConfigsSlice.reducer;
