import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import i18n from 'assets/translations';
import { RootState } from 'redux/store.types';

import { IGamesUUID } from './gameUUIDConfigs.types';
import { gameUUIDThunk } from './gameUUIDConfigs.thunk';

const initialState: IGamesUUID = {
  data: [
    {
      game_uuid: '',
      name: '',
      image: null,
      has_mobile: false,
      has_freebet: false,
      has_demo: false,
    },
  ],
  error: null,
  isLoading: false,
};

export const gameUUIDConfigsSlice = createSlice({
  name: 'gameUUIDConfigs',
  initialState,
  reducers: {
    setSelectedId: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(gameUUIDThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(gameUUIDThunk.rejected, state => {
        state.isLoading = false;
        toast.error(i18n.t('You have incorrect field!'));
      })
      .addCase(gameUUIDThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setSelectedId } = gameUUIDConfigsSlice.actions;

// SELECTORS
export const selectGameUUID = (state: RootState) =>
  state.gameUUIDConfigs.data.map(item => item?.game_uuid).join(',');
export default gameUUIDConfigsSlice.reducer;
