import { createAsyncThunk } from '@reduxjs/toolkit';

import { headerService } from 'services/header.service';

export const headerProjectsThunk = createAsyncThunk(
  'header/getProjects',
  async () => {
    const response = await headerService.getProjects();
    return response;
  },
);

export const headerCurrenciesThunk = createAsyncThunk(
  'header/getCurrencies',
  async () => {
    const response = await headerService.getCurrencies();
    return response;
  },
);
