import { FC, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { setPartnerId } from 'redux/reducers/headerConfig/headerConfigs.slice';
import { setCurrentProjectPermission } from 'redux/reducers/serverConfigs/serverConfigs.slice';

type PropTypes = {
  isMobile: boolean;
};
export const HeaderPartners: FC<PropTypes> = ({ isMobile }) => {
  const dispatch = useAppDispatch();

  const userPermissions = useSelector(
    (state: RootState) => state.serverConfigs.user.tokenData.user.permission,
  );

  const handleChange = (value: number) => {
    dispatch(setPartnerId(value));
  };

  let selectedId = useSelector(
    (state: RootState) => state.headerConfigs.partnerId,
  );

  // if (!selectedId) {
  //   selectedId = useSelector(
  //     (state: RootState) =>
  //       state.serverConfigs.user.tokenData.user.partners[0].id,
  //   );
  //   dispatch(setPartnerId(selectedId));
  // }
  const options = useSelector((state: RootState) =>
    state.serverConfigs.user.tokenData.user.partners?.map(item => ({
      value: item.id,
      label: item.name,
    })),
  );
  if (!selectedId) {
    selectedId = options[0].value;
    dispatch(setPartnerId(selectedId));
  }
  const defaultValue = selectedId;

  // useEffect(() => {
  //   if (!data?.length) return;
  //   const projectName = query.get('project_name');
  //   const projectId = data?.find(e => e.name === projectName)?.id;
  //   if (projectId) {
  //     handleChange(projectId);
  //   }
  // }, [data]);

  useEffect(() => {
    const userCurrentProjectPermission = userPermissions.find(
      e => e.project.id === defaultValue,
    )?.role;
    if (userCurrentProjectPermission)
      dispatch(setCurrentProjectPermission(userCurrentProjectPermission));
  }, [defaultValue, userPermissions]);

  return (
    <>
      <Form layout='inline'>
        <Form.Item
          label={isMobile ? '' : 'Partner'}
          labelCol={{ span: isMobile ? 9 : 8 }}>
          <Select
            style={{ width: isMobile ? '8rem' : '10.5rem' }}
            value={defaultValue}
            placeholder='Select a partner'
            onChange={handleChange}
            // loading={isLoading}
            options={options}
          />
        </Form.Item>
      </Form>
    </>
  );
};
