import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Divider, Flex, Layout, Modal, theme } from 'antd';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { useIsMobile } from '../../../helpers/hooks.helpers';
import { useAppDispatch } from '../../../redux/hooks/redux.hooks';
import {
  selectIsMenuSidebarOpen,
  toggleMenuSidebar,
} from '../../../redux/reducers/appConfigs/appConfigs.slice';
// import ABCDLogo from '../ABCDLogo/ABCDLogo';

import classes from './Header.module.scss';
import { HeaderProjects } from './components/-Projects/HeaderProjects';
import { HeaderTimeZone } from './components/-TimeZone';
import { HeaderCurrencies } from './components/-Currencies/HeaderCurrencies';
import { HeaderPartners } from './components/-Partner';
// import { NotificationTrigger } from './components/-NotificationTrigger';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();
  const isMobile = useIsMobile();
  const isSidebarOpen = useSelector(selectIsMenuSidebarOpen);
  const TriggerIcon = isSidebarOpen ? MenuFoldOutlined : MenuUnfoldOutlined;

  const [open, setOpen] = useState(false);

  const onTriggerClick = () => {
    dispatch(toggleMenuSidebar(!isSidebarOpen));
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Layout.Header
      style={{ background: token.colorBgContainer }}
      className={classes.root}>
      <div className={classes.headerActions}>
        <div className={classes.headerLeftActions}>
          <TriggerIcon className={classes.trigger} onClick={onTriggerClick} />
        </div>
        <>
          {isMobile && <HeaderPartners isMobile={isMobile} />}
          {isMobile && <HeaderProjects isMobile={isMobile} />}

          {isMobile ? (
            <>
              <SettingOutlined onClick={() => setOpen(true)} />
              <Modal
                className={classes.modal}
                width='100%'
                title='Settings'
                open={open}
                onCancel={closeModal}
                footer={
                  <Button
                    style={{ marginTop: 160 }}
                    type='primary'
                    onClick={closeModal}
                    key='submit'>
                    OK
                  </Button>
                }>
                <Divider />
                <HeaderCurrencies isMobile={isMobile} />
                <HeaderTimeZone isMobile={isMobile} />
              </Modal>
            </>
          ) : (
            <Flex
              justify='flex-end'
              align='center'
              gap='middle'
              style={{ width: '100%', paddingRight: 16 }}>
              <HeaderPartners isMobile={isMobile} />
              <HeaderCurrencies isMobile={isMobile} />
              <HeaderProjects isMobile={isMobile} />
              <HeaderTimeZone isMobile={isMobile} />
            </Flex>
          )}
        </>
        {/* <div className={classes.headerRightActions}>
          <NotificationTrigger isInSidebar={false} />
        </div> */}
        {/* {isMobile && <ABCDLogo />} */}
      </div>
    </Layout.Header>
  );
};

export default Header;
