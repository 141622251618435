import { Divider, Table } from 'antd';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';

import { UsersColumns, ProjectColumns } from './Dashboard.const';
import { DashboardStatistics } from './-Statistics';
import { useDashboardMutations } from './hooks/useDashboardMutations';
import { DashboardForm } from './-Form';
import { IDashboardFiltersForm, IGeneralReportData } from './Dashboard.types';

export const Dashboard: FC = () => {
  const { gamesMutationData, generalMutationData } = useDashboardMutations();

  const currencyData = useSelector(
    (state: RootState) => state.headerConfigs.currencies,
  );
  const selectedCurrency = useSelector(
    (state: RootState) => state.headerConfigs.selectedCurrency,
  );

  const { timeFrom, timeTo } = useSelector(
    (state: RootState) => state.appConfigs.timeRange,
  );
  const projectsData = useSelector(
    (state: RootState) => state.headerConfigs.data,
  );
  const selectId = useSelector(
    (state: RootState) => state.headerConfigs.selectedId,
  );
  const selectedPartnerId = useSelector(
    (state: RootState) => state.headerConfigs.partnerId,
  );

  if (
    gamesMutationData &&
    gamesMutationData.data &&
    gamesMutationData.data.data
  ) {
    gamesMutationData.data.data = gamesMutationData.data.data.map(
      (item: IGeneralReportData) => {
        return {
          ...item,
          bet: `${item.bet}`,
          win: `${item.win}`,
          ggr: `${item.ggr}`,
        };
      },
    );
  }

  const generalReportData = generalMutationData.data?.data;
  const gamesReportData = gamesMutationData.data?.data;
  const totalWin = `${generalReportData?.win || 0} `;
  const totalBet = `${generalReportData?.bet || 0} `;
  const ggr = `${generalReportData?.ggr || 0} `;
  const rtp = generalReportData?.rtp;
  const totalSessions = generalReportData?.total_sessions;
  const totalPlayers = generalReportData?.total_players;
  const allTotal = gamesReportData?.data?.data.length;
  const statsPerProject = generalReportData?.stats_per_project;
  const serviceFee = generalReportData?.service_fee;

  useEffect(() => {
    const initialValues = {
      timeFrom,
      timeTo,
      currency: currencyData[selectedCurrency],
    };
    generalMutationData.mutate(initialValues);
    gamesMutationData.mutate(initialValues);
  }, [projectsData, selectedCurrency, selectedPartnerId, selectId]);

  const handleMutation = (data: IDashboardFiltersForm) => {
    generalMutationData.mutate(data);
    gamesMutationData.mutate(data);
  };

  return (
    <>
      <div>
        <DashboardForm dashboardHandleMutation={handleMutation} />
        <DashboardStatistics
          totalWin={totalWin}
          totalBet={totalBet}
          ggr={ggr}
          rtp={rtp}
          totalSessions={totalSessions}
          totalPlayers={totalPlayers}
          serviceFee={serviceFee}
        />
      </div>
      <br />
      {statsPerProject && statsPerProject.length && (
        <>
          <Divider dashed orientation='left'>
            Project
          </Divider>
          <Table
            dataSource={statsPerProject}
            style={{ marginTop: 50 }}
            rowKey='id'
            loading={generalMutationData.isLoading}
            columns={ProjectColumns}
            scroll={{ x: true }}
            bordered
            pagination={{
              position: ['bottomCenter'],
              total: allTotal,
            }}
          />
        </>
      )}

      <Divider dashed orientation='left'>
        Games
      </Divider>
      <Table
        dataSource={gamesMutationData.data?.data}
        style={{ marginTop: 50 }}
        rowKey='id'
        loading={gamesMutationData.isLoading}
        columns={UsersColumns}
        scroll={{ x: true }}
        bordered
        pagination={{
          pageSize: 100,
          position: ['bottomCenter'],
          total: allTotal,
        }}
      />
    </>
  );
};
