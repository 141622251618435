import { FC, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import { headerCurrenciesThunk } from 'redux/reducers/headerConfig/headerConfigs.thunk';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { setSelectedCurrency } from 'redux/reducers/headerConfig/headerConfigs.slice';

type PropTypes = {
  isMobile: boolean;
};
export const HeaderCurrencies: FC<PropTypes> = ({ isMobile }) => {
  const dispatch = useAppDispatch();
  const { currencies, isLoading } = useSelector(
    (state: RootState) => state.headerConfigs,
  );

  const handleChange = (value: number) => {
    dispatch(setSelectedCurrency(value));
    // dispatch(headerCurrenciesThunk());
  };

  useEffect(() => {
    dispatch(headerCurrenciesThunk());
  }, []);
  const defaultValue = useSelector(
    (state: RootState) => state.headerConfigs.selectedCurrency,
  );

  const options = currencies?.map((item, index) => ({
    value: index,
    label: item,
  }));

  return (
    <>
      <Form layout='inline'>
        <Form.Item label='Currency:' labelCol={{ span: isMobile ? 8 : 10 }}>
          <Select
            style={{ width: '10.7rem', paddingLeft: '7px' }}
            placeholder='Select a currency'
            onChange={handleChange}
            loading={isLoading}
            options={options}
            showSearch
            optionFilterProp='children'
            defaultValue={defaultValue}
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input?.toUpperCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};
